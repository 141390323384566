<template>
  <b-card class="blog-edit-wrapper">
    <div class="text-center">
      <h4 class="mt-5">
        إضافة دورة جديدة
        <feather-icon icon="Edit2Icon" />
      </h4>
    </div>
    <!-- form -->
    <validation-observer ref="addCourseFormvalidate">
      <b-form
        ref="form"
        class="mt-2"
        style="width: 100%"
        @submit.prevent="save"
      >
        <b-row>
          <b-col md="12">
            <label>حفظ الدورة باسم</label>

            <b-form-radio-group
              v-model="addCourseForm.save_as"
              label="saveas"
              class="custom-control-warning mb-2"
            >
              <b-form-radio value="Online">
                اونلاين
              </b-form-radio>
              <b-form-radio value="Classical">
                كلاسيك
              </b-form-radio>
              <b-form-radio value="Both">
                كلاهما
              </b-form-radio>
            </b-form-radio-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="اسم الدورة "
              label-for="blog-edit-title"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Course Name"
                rules="required"
              >
                <b-form-input
                  id="blog-edit-title"
                  v-model="addCourseForm.name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="فئة"
              label-for="blog-edit-category"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Category"
                rules="required"
              >
                <v-select
                  id="blog-edit-category"
                  v-model="addCourseForm.category_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="type"
                  :options="categoriesList"
                  :reduce="(val) => val.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="الرابط"
              label-for="blog-edit-slug"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="In-Link Name"
                rules="required"
              >
                <b-form-input
                  id="blog-edit-slug"
                  v-model="addCourseForm.link_id"
                  @input="removeSpace($event)"
                  @change="checkLink"
                />
                <small
                  v-if="!validLink"
                  class="text-danger"
                > هذا الرابط موجود مسبقا</small>
                <small class="text-danger">{{ errors[0] }}</small>
                <p v-if="addCourseForm.save_as != 'Online'">
                  رابط الدرورة الكلاسيكية بعد الانشاء:{{
                    `https://ar.lpcentre.com/${addCourseForm.link_id}`
                  }}
                </p>
                <p v-if="addCourseForm.save_as != 'Classical'">
                  رابط الدرورة الاونلاين بعد الانشاء:{{
                   `https://ar.lpcentre.com/online/${addCourseForm.link_id}`
                  }}
                </p>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="رابط اليوتيوب"
              label-for="blog-edit-category"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="YouTube Link"
              >
                <b-form-input
                  id="video_link"
                  v-model="addCourseForm.video_link"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="المدة"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Duration [Days]"
                rules="required"
              >
                <b-form-input
                  id="duration"
                  v-model="addCourseForm.duration"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="mb-2"
          >
            <div class="border rounded p-2">
              <h4 class="mb-1">
                Tab صورة
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <div class="d-inline-block">
                  <b-media-aside>
                    <b-img
                      ref="refPreviewE2"
                      :src="Tabimage"
                      height="150"
                      width="200"
                      class="rounded mr-2 mb-1 mb-md-0 col-12"
                    />
                  </b-media-aside>

                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    class="rounded ml-1 mt-2 text-center"
                    @click="
                      refPreviewE2.src = Tabimage;
                      addCourseForm.tab_image = '';
                    "
                  >
                    ازالة الصورة
                  </b-button>
                </div>
                <b-media-body>
                  <b-card-text class="mt-1" />
                  <div class="d-inline-block mt-1">
                    <b-form-group
                      label=""
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Tab Image "
                      >
                        <b-form-file
                          ref="refInputE2"
                          v-model="addCourseForm.tab_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="اختر ملف"
                          @input="inputImageRendererTab"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="نص بديل للصورة"
                      label-for="blog-edit-title"
                    >
                      <b-form-input
                        id="alt-image"
                        v-model="addCourseForm.alt_tab_image"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
            <div class="border rounded p-2">
              <h4 class="mb-1">
                Header صورة
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <div class="d-inline-block">
                  <b-media-aside>
                    <b-img
                      ref="refPreviewEl"
                      :src="Tabimage"
                      height="150"
                      width="200"
                      class="rounded mr-2 mb-1 mb-md-0"
                    />
                  </b-media-aside>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    class="rounded ml-1 mt-2 text-center"
                    @click="
                      refPreviewEl.src = Tabimage;
                      addCourseForm.header_image = '';
                    "
                  >
                    ازالة الصورة
                  </b-button>
                </div>
                <b-media-body>
                  <b-card-text class="mt-1" />

                  <div class="d-inline-block mt-1">
                    <b-form-group

                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Header Image "
                      >
                        <b-form-file
                          ref="refInputEl"
                          v-model="addCourseForm.header_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="اختر ملف"
                          @input="inputImageRenderer"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="نص بديل للصورة"
                      label-for="blog-edit-title"
                    >
                      <b-form-input
                        id=" alt_header_image"
                        v-model="addCourseForm.alt_header_image"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
            <div class="border rounded p-2">
              <h4 class="mb-1">
                Og Graph صورة
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <div class="d-inline-block">
                  <b-media-aside>
                    <b-img
                      ref="refPreviewE3"
                      :src="Tabimage"
                      height="110"
                      width="170"
                      class="rounded mr-2 mb-1 mb-md-0 col-12"
                    />
                  </b-media-aside>

                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    class="rounded ml-1 mt-2 text-center"
                    @click="
                      refPreviewE3.src = Tabimage;
                      addCourseForm.og_image = '';
                    "
                  >
                    ازالة الصورة
                  </b-button>
                </div>
                <b-media-body>
                  <b-card-text class="mt-1" />
                  <div class="d-inline-block mt-1">
                    <b-form-group

                      label-for="blog-tab-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Tab Image"
                      >
                        <b-form-file
                          ref="refInputE3"
                          v-model="addCourseForm.og_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="اختر ملف"
                          @input="inputImageRendererTab3"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="نص بديل للصورة"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id="alt-image"
                        v-model="addCourseForm.alt_og_image"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
          </b-col>
          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
              v-if="!isLoading"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              حفظ
            </b-button>

            <b-button
              v-if="isLoading"
              variant="primary"
              disabled
              class="mr-1"
            >
              جار الحفظ...
              <b-spinner small />
              <span class="sr-only" />
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BFormRadio,

  BFormRadioGroup,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
 
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'

import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, reactive } from '@vue/composition-api'
import Vue from 'vue'
import store from '@/store'

export default {
  setup() {
    const refInputE3 = ref(null)

    const refPreviewE3 = ref(null)
    const isLoading = ref(false)
    const validLink = ref(true)
    const { inputImageRendererTab3 } = useInputImageRenderer(
      refInputE3,
      base64 => {
        refPreviewE3.value.src = base64
      },
    )
    const checkLink = link => {
      const formData = new FormData()

      if (addCourseForm.save_as == 'Online') {
        formData.append('online', 1)
      } else {
        formData.append('online', 0)
      }

      formData.append('slug', link)
      store.dispatch('courses/checkLink', formData).then(response => {
        validLink.value = response.data.valid
      })
    }
    // const store = useStore();
    const refInputEl = ref(null)
    const refInputE2 = ref(null)
    const refPreviewEl = ref(null)
    const refPreviewE2 = ref(null)
    const refPreviewE4 = ref(null)

    const Tabimage = ref('media/svg/files/blank-image.svg')
    const categoriesList = ref([])
    store.dispatch('categories/AllCategory').then(response => {
      categoriesList.value = response.data.data
    })
    const addCourseForm = reactive({
      save_as: '',
      name: '',
      link_id: '',
      tab_image: [],
      header_image: [],
      alt_tab_image: '',
      alt_og_image: '',
      alt_header_image: '',
      duration: '',
      video_link: '',
      category_id: '',
      og_image: [],
    })
    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      base64 => {
        refPreviewEl.value.src = base64
      },
    )
    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      base64 => {
        refPreviewE2.value.src = base64
      },
    )
    const removeSpace = target => {
      addCourseForm.link_id = target
        .toLowerCase() // LowerCase
        .replace(/\s+/g, '-') // space to -
        .replace(/&/g, '') // & to and
        .replace(/--/g, '-')
        .replace(/[.,]/g, '-')
        .replace(/[_]/g, '')
        .replace(/[!''""=]/g, '')
        .replace(/[!?%$^~*#()+]/g, '')
    }
    const addCourseFormvalidate = ref()
    const form = ref()
    const save = () => {
      addCourseFormvalidate.value.validate().then(success => {
        if (success) {
          const formData = new FormData()

          formData.append('save_as', addCourseForm.save_as)
          formData.append('name', addCourseForm.name)
          formData.append('header_image', addCourseForm.header_image)
          formData.append('name', addCourseForm.name)
          formData.append('link_id', addCourseForm.link_id)
          formData.append('tab_image', addCourseForm.tab_image)
          formData.append(' header_image', addCourseForm.header_image)
          formData.append('alt_tab_image', addCourseForm.alt_tab_image)
          formData.append('alt_header_image', addCourseForm.alt_header_image)
          formData.append('duration', addCourseForm.duration)
          formData.append(' video_link', addCourseForm.video_link)
          formData.append('category_id', addCourseForm.category_id)
          formData.append('og_image', addCourseForm.og_image)
          formData.append('alt_og_image', addCourseForm.alt_og_image)

          if (validLink.value == true) {
            isLoading.value = true
            store
              .dispatch('courses/addCourse', formData)
              .then(response => {
                isLoading.value = false
                refPreviewEl.value.src = 'media/svg/files/blank-image.svg'
                refPreviewE2.value.src = 'media/svg/files/blank-image.svg'
                refPreviewE3.value.src = 'media/svg/files/blank-image.svg'
                addCourseForm.category_id = ''
                form.value.reset()
                Vue.swal({
                  title: 'تم الاضافة بنجاح ',
                  icon: 'success',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                    confirmButtonText: 'تم',
                  },
                  buttonsStyling: false,
                })
              })
              .catch(error => {
                isLoading.value = false

                Vue.swal({
                  title: '',
                  text: `${error.response.data.message}`,
                  icon: 'error',
                  confirmButtonText: 'حسنا',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
          } else {
            Vue.swal({
              title: '',
              text: 'من فضلك ادخل رابط صحيح',
              icon: 'error',
              confirmButtonText: 'حسنا',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
        }
      })
    }
    return {
      refInputEl,
      refPreviewEl,
      refInputE2,
      refPreviewE2,
      inputImageRendererTab,
      Tabimage,
      addCourseForm,
      inputImageRenderer,
      addCourseFormvalidate,
      categoriesList,
      form,
      removeSpace,
      refInputE3,
      refPreviewE3,
      inputImageRendererTab3,
      validLink,
      checkLink,
      isLoading,
      save,
      required,
      email,
    }
  },

  components: {
    BCard,
    BMedia,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BFormRadio,
  
    BFormRadioGroup,
    BForm,
   
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,

  },

  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
<style>
.mb-2.col-12 {
  margin: -1px !important;
}
.border.rounded.p-2 {
  margin: 8px 1px !important;
}
.col-12 {
  margin: 0px;
}
</style>
